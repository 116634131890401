







































import { computed, defineComponent, ref } from '@vue/composition-api';
import ReceiptListItem from './ReceiptListItem.vue';
import { findInDateRange } from '@/api/receipt';
import { ReceiptDto } from '@/common/types';
import { format } from 'date-fns';

export default defineComponent({
  components: {
    ReceiptListItem,
  },
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    from: {
      required: true,
      type: Date,
    },
    to: {
      required: true,
      type: Date,
    },
  },
  setup(props) {
    const receipts = ref<ReceiptDto[]>([]);
    const loading = ref(false);
    const error = ref('');

    const loadReceipts = async () => {
      if (loading.value) return;
      try {
        loading.value = true;
        receipts.value = await findInDateRange(
          props.establishmentId,
          format(props.from, 'yyyy-MM-dd'),
          format(props.to, 'yyyy-MM-dd'),
        );
      } catch (e) {
        error.value = e;
      } finally {
        loading.value = false;
      }
    };

    loadReceipts();

    const sorted = computed(() => {
      return receipts.value.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        if (a.closedAt < b.closedAt) {
          return -1;
        }
        if (a.closedAt > b.closedAt) {
          return 1;
        }
        return 0;
      });
    });

    return {
      receipts,
      loading,
      error,
      sorted,
      loadReceipts,
    };
  },
});
