


































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';

import WeatherWidget from './widgets/WeatherWidget.vue';
import ReservationWidget from './widgets/ReservationWidget.vue';
import PlanningWidget from './widgets/PlanningWidget.vue';
import Sidebar from './sidebar/Sidebar.vue';
import Startup from './startup/Startup.vue';
import ShiftReviews from './shiftReview/ShiftReviews.vue';
import IncidentReports from './incidentReports/IncidentReports.vue';
import JournalNotes from './journalNotes/JournalNotes.vue';
import Shutdown from './shutdown/Shutdown.vue';
import { findById } from '@/api/journals';
import { Journal } from '@/common';

export default defineComponent({
  components: {
    WeatherWidget,
    ReservationWidget,
    PlanningWidget,
    Sidebar,
    Startup,
    ShiftReviews,
    IncidentReports,
    JournalNotes,
    Shutdown,
  },
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const journal = ref<Journal | null>(null);

    watch(
      () => props.journalId,
      async id => {
        journal.value = await findById(id);
      },
      { immediate: true },
    );

    return {
      journal,
      establishment: computed(() => journal.value?.establishment),
    };
  },
});
