
















































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { Journal, JournalNote } from '@/common';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';

interface NoteObject {
  date: Date;
  id: number;
  subject: string;
  comment: string;
  rating: {
    rating: number;
    icon: IconObject;
  };
  note: JournalNote;
}

interface IconObject {
  icon: string;
  variant: string;
}

export default defineComponent({
  props: {
    journals: {
      required: true,
      type: Array as PropType<Journal[]>,
    },
  },
  setup(props) {
    const showModal = ref(false);
    const selectedNote = ref<JournalNote>({
      id: 0,
      journalId: 0,
      subject: '',
      comment: '',
      rating: 0,
    });

    const openModal = (data: JournalNote) => {
      selectedNote.value = data;
      showModal.value = true;
    };

    const getRatingIcon = (rating: number | undefined | null) => {
      const result: IconObject = {
        icon: '',
        variant: '',
      };

      if (rating === undefined || rating === null) {
        result.icon = 'emoji-smile';
        result.variant = 'secondary';
      } else if (rating === 1) {
        result.icon = 'emoji-frown';
        result.variant = 'danger';
      } else if (rating === 5) {
        result.icon = 'emoji-laughing';
        result.variant = 'success';
      }
      return result;
    };

    const formatDate = (date: Date) => {
      return format(date, 'EEEE', { locale });
    };

    const notes = computed(() => {
      const notes: NoteObject[] = [];
      props.journals.forEach(journal => {
        if (journal.journalNotes) {
          journal.journalNotes.forEach(note => {
            notes.push({
              id: note.id,
              date: new Date(journal.dob),
              subject: note.subject,
              comment: note.comment,
              rating: {
                rating: note.rating,
                icon: getRatingIcon(note.rating),
              },
              note: note,
            });
          });
        }
      });
      return notes;
    });

    return {
      showModal,
      selectedNote,
      notes,
      openModal,
      getRatingIcon,
      formatDate,
      fields: [
        {
          key: 'date',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'rating',
          label: '',
          sortable: true,
        },
        {
          key: 'subject',
          label: 'Onderwerp',
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ],
    };
  },
});
