

































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Establishment } from '@/common/types';
import { endOfWeek, format, getISOWeek, startOfWeek } from 'date-fns';
import { addWeeks, subWeeks } from 'date-fns/esm';
import { useRouter } from '@u3u/vue-hooks';

export default defineComponent({
  props: {
    date: {
      required: true,
      type: Date,
    },
    establishment: {
      required: true,
      type: Object as PropType<Establishment>,
    },
  },
  setup(props) {
    const { router } = useRouter();
    const showCalendar = ref(false);

    const innerDate = ref(format(props.date, 'yyyy-MM-dd'));

    watch(innerDate, date => {
      router.replace({
        name: 'dashboard',
        params: { establishmentId: String(props.establishment.id) },
        query: { date },
      });
      showCalendar.value = false;
    });

    const next = () => {
      innerDate.value = format(
        startOfWeek(addWeeks(props.date, 1), { weekStartsOn: 1 }),
        'yyyy-MM-dd',
      );
    };

    const prev = () => {
      innerDate.value = format(
        startOfWeek(subWeeks(props.date, 1), { weekStartsOn: 1 }),
        'yyyy-MM-dd',
      );
    };

    return {
      showCalendar,
      innerDate,
      weekNumber: computed(() => getISOWeek(props.date)),
      formatStartOfWeek: computed(() =>
        format(startOfWeek(props.date, { weekStartsOn: 1 }), 'dd-MM-yyyy'),
      ),
      formatEndOfWeek: computed(() =>
        format(endOfWeek(props.date, { weekStartsOn: 1 }), 'dd-MM-yyyy'),
      ),
      next,
      prev,
    };
  },
});
