




















































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { format, parseISO } from 'date-fns';
import UpdateContactDetails from '@/components/receipts/UpdateContactDetails.vue';
import { ReceiptDto } from '@/common/types';
import { downloadInvoicePdf } from '@/api/receipt';

const currencyFormatter = Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

export default defineComponent({
  components: {
    UpdateContactDetails,
  },
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    receipt: {
      required: true,
      type: Object as PropType<ReceiptDto>,
    },
  },
  setup(props, { emit }) {
    const showSidebar = ref(false);

    const receiptDate = computed(() => {
      const d = parseISO(props.receipt.date);
      return format(d, 'dd-MM-yyyy');
    });

    const amount = computed(() => {
      return currencyFormatter.format(props.receipt.salesAmount);
    });

    const debtorName = computed(() => {
      if (props.receipt.contactDetails?.debtorName) {
        return props.receipt.contactDetails.debtorName;
      } else if (props.receipt.accountIdent) {
        return props.receipt.accountIdent;
      } else if (props.receipt.accountNumber) {
        return props.receipt.accountNumber;
      } else {
        return 'ONBEKEND';
      }
    });

    const download = () => {
      if (props.receipt.invoice) {
        downloadInvoicePdf(props.receipt.id);
      }
    };

    const refresh = () => {
      showSidebar.value = false;
      emit('refresh');
    };

    return {
      showSidebar,
      receiptDate,
      amount,
      debtorName,
      download,
      refresh,
    };
  },
});
