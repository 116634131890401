

















































import { defineComponent, PropType } from '@vue/composition-api';
import { ReceiptDto } from '@/common';

export default defineComponent({
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    receipt: {
      required: true,
      type: Object as PropType<ReceiptDto>,
    },
  },
  setup() {
    return {
      assetsUrl: process.env.VUE_APP_JOURNAL_ASSETS_URL,
      format: (amount?: number) => {
        const strVal = amount?.toFixed(2) ?? '-';
        return `€ ${strVal}`;
      },
    };
  },
});
