





















































import { computed, defineComponent, PropType } from '@vue/composition-api';
import WeatherIcon from '@/components/weather/WeatherIcon.vue';
import WeatherWindIcon from '@/components/weather/WeatherWindIcon.vue';
import { Establishment } from '@/common';
import useWeather from '@/util/weather';

interface WeatherData {
  tempDay: string;
  tempEve: string;
  weatherIcon: string;
  wind: number;
  windIcon: number;
  precipitation: string;
}

const { ms2Bft, ms2Kmh } = useWeather();

export default defineComponent({
  components: {
    WeatherIcon,
    WeatherWindIcon,
  },
  props: {
    weather: {
      required: true,
      type: Object as PropType<WeatherData>,
    },
    establishment: {
      required: true,
      type: Object as PropType<Establishment>,
    },
  },
  setup(props) {
    const windWarning = computed(() => {
      if (props.establishment.weatherWindThreshold) {
        return props.weather.wind >= props.establishment.weatherWindThreshold;
      } else {
        return false;
      }
    });

    const wind = computed(() => {
      if (!props.establishment.weatherWindUnit) {
        return props.weather.wind;
      }

      switch (props.establishment.weatherWindUnit) {
        case 'KMH':
          return ms2Kmh(props.weather.wind);
        case 'BFT':
          return ms2Bft(props.weather.wind);
        default:
          return Math.round(props.weather.wind * 10) / 10;
      }
    });

    const windUnit = computed(() => {
      if (!props.establishment.weatherWindUnit) {
        return 'ms';
      }

      switch (props.establishment.weatherWindUnit) {
        case 'KMH':
          return 'km/h';
        case 'BFT':
          return 'bft';
        default:
          return 'ms';
      }
    });

    return {
      windWarning,
      wind,
      windUnit,
    };
  },
});
