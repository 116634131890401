




































































































































































import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Journal } from '@/common/types';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import { DailyBalance, DailyBalanceDto } from '@/api/dailyBalanceApi';

interface TableColumns {
  key: string;
  label: string;
  sortable: boolean;
}

interface TableRow {
  date: string;
  day: string;
  startup: IconObject;
  misc: IconObject;
  employees: IconObject;
  kitchen: IconObject;
  guests: IconObject;
  diff: string;
}

interface IconObject {
  icon: string;
  variant: string;
  comment: string;
}

const currency = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

export default defineComponent({
  props: {
    journals: {
      required: true,
      type: Array as PropType<Journal[]>,
    },
    dailyBalanceData: {
      required: true,
      type: Array as PropType<DailyBalanceDto[]>,
    },
  },
  setup(props) {
    const loading = ref(false);
    const error = ref('');

    const day = ref(true);
    const evening = ref(false);
    const selectedDay = ref('');
    const fields = ref<TableColumns[]>([
      {
        key: 'day',
        label: 'Dag',
        sortable: true,
      },
      {
        key: 'date',
        label: 'Datum',
        sortable: true,
      },
      {
        key: 'startup',
        label: 'Start',
        sortable: false,
      },
      {
        key: 'open',
        label: '',
        sortable: false,
      },
    ]);

    const items = ref<TableRow[]>([]);

    function getDailyBalance(date: Date): string {
      const dob = format(date, 'yyyy-MM-dd') + 'T00:00:00.000Z';
      const balance = props.dailyBalanceData.filter(d => d.dob === dob)?.[0];
      if (balance) {
        const wrapper = new DailyBalance(balance);
        if (wrapper.getStoredDiffAmount()) {
          return String(wrapper.getDifferenceAmount());
        }
      }
      return '';
    }

    function getRatingIcon(rating: number | undefined | null, comment: string | undefined) {
      const result: IconObject = {
        icon: '',
        variant: '',
        comment: comment !== undefined ? comment : '',
      };

      if (rating === undefined || rating === null || rating < 0) {
        result.icon = 'emoji-smile';
        result.variant = 'secondary';
      } else if (rating === 0) {
        result.icon = 'emoji-frown';
        result.variant = 'danger';
      } else if (rating === 1) {
        result.icon = 'emoji-laughing';
        result.variant = 'success';
      }
      return result;
    }

    async function loadJournals(daypart: string) {
      selectedDay.value = daypart;
      if (daypart === 'dag') {
        day.value = true;
        evening.value = false;
      } else {
        day.value = false;
        evening.value = true;
      }
      items.value = [];
      props.journals.forEach(journal => {
        if (journal.name === daypart) {
          items.value.push({
            date: format(new Date(journal.dob), 'yyyy-MM-dd', { locale }),
            day: format(new Date(journal.dob), 'EEEE', { locale }),
            startup: getRatingIcon(journal.startUpRating, journal.startUpComment),
            misc: getRatingIcon(journal.closeMiscRating, journal.closeMiscComment),
            employees: getRatingIcon(journal.closeStaffRating, journal.closeStaffComment),
            kitchen: getRatingIcon(journal.closeKitchenRating, journal.closeKitchenComment),
            guests: getRatingIcon(journal.closeGuestsRating, journal.closeGuestsComment),
            diff: getDailyBalance(new Date(journal.dob)),
          });
        }
      });
    }

    watch(
      () => props.journals,
      () => loadJournals('dag'),
      { immediate: true },
    );

    function formatCurrency(val: number) {
      return currency.format(val);
    }

    function isToday(date: string) {
      const today = format(new Date(), 'yyyy-MM-dd');
      const checkDate = format(new Date(date), 'yyyy-MM-dd');
      return today === checkDate;
    }

    return {
      loading,
      error,
      day,
      evening,
      selectedDay,
      fields,
      items,
      formatCurrency,
      isToday,
      loadJournals,
    };
  },
});
