

























































































































































































































































































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import dailyBalanceApi, {
  DailyBalance,
  DailyBalanceDto,
  DailyBalancePayment,
} from '@/api/dailyBalanceApi';
import { AlohaEstablishment } from '@/common';
import ReceiptList from '@/components/receipts/ReceiptList.vue';
import { addDays, format } from 'date-fns';
import { getTenders } from '@/api/tenders';
import { AppliedTender } from '@/api/types';

const defaultCoins = {
  5: 0,
  10: 0,
  20: 0,
  50: 0,
  100: 0,
  200: 0,
  500: 0,
};

const CurrencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

export default defineComponent({
  components: {
    ReceiptList,
  },
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    dob: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const loading = ref(false);
    const updating = ref(false);
    const errorMessage = ref('');
    const establishment = ref<AlohaEstablishment>(
      props.establishmentId === 1 ? 'WALRUS_SN' : 'WALRUS_LWD',
    );

    const dailyBalanceData = ref<DailyBalanceDto | null>(null);
    const dailyBalance = ref<DailyBalance | undefined>();
    const tenders = ref<AppliedTender[]>([]);
    const coinModel = ref<Record<number, number>>({ ...defaultCoins });
    const pinModel = ref<number[]>([]);
    const paymentsModel = ref<DailyBalancePayment[]>([]);

    const debtorFrom = computed(() => {
      return new Date(props.dob);
    });

    const debtorTo = computed(() => {
      return addDays(debtorFrom.value, 1);
    });

    watch(
      [coinModel, pinModel, paymentsModel],
      () => {
        if (!dailyBalanceData.value) {
          return;
        }
        dailyBalance.value = new DailyBalance({
          ...dailyBalanceData.value,
          userData: {
            ...dailyBalanceData.value.userData,
            coins: coinModel.value,
            pins: pinModel.value,
            payments: paymentsModel.value,
          },
        });
      },
      { deep: true },
    );

    async function loadDailyBalance() {
      try {
        const dailyBalance = await dailyBalanceApi.search({
          establishmentId: props.establishmentId,
          dob: props.dob,
        });

        if (dailyBalance.length) {
          dailyBalanceData.value = dailyBalance[0];
        }

        coinModel.value = (dailyBalanceData.value?.userData?.coins as Record<number, number>) ?? {
          ...defaultCoins,
        };
        pinModel.value = (dailyBalanceData.value?.userData?.pins as number[]) ?? [];
        paymentsModel.value =
          (dailyBalanceData.value?.userData?.payments as DailyBalancePayment[]) ?? [];
      } catch (e) {
        console.error(e);
      }
    }

    async function loadTenders() {
      try {
        const dob = new Date(props.dob);
        const _tenders = await getTenders({
          establishmentId: props.establishmentId,
          start: props.dob,
          end: format(addDays(dob, 1), 'yyyy-MM-dd'),
        });
        tenders.value = _tenders?.[props.dob] ?? [];
      } catch (e) {
        console.error(e);
      }
    }

    async function loadData() {
      try {
        loading.value = true;
        await Promise.all([loadDailyBalance(), loadTenders()]);
      } catch (e) {
        errorMessage.value = e as string;
      } finally {
        loading.value = false;
      }
    }

    loadData();

    function currency(num: number) {
      return CurrencyFormatter.format(num);
    }

    function totalDebtorAmount() {
      return dailyBalance.value?.getDebtorAmount() ?? 0;
    }

    function totalCoinsAmount() {
      return dailyBalance.value?.getCoinsAmount() ?? 0;
    }

    function coinsDifferenceAmount() {
      return dailyBalance.value?.getCoinsDifferenceAmount() ?? 0;
    }

    function totalUserPinAmount() {
      return dailyBalance.value?.getUserPinAmount() ?? 0;
    }

    function totalIZettle() {
      return dailyBalance.value?.getIZettleAmount() ?? 0;
    }

    function totalPayments() {
      return dailyBalance.value?.getPaymentsAmount() ?? 0;
    }

    function totalSpecialPaymentsAmount() {
      return dailyBalance.value?.getSpecialPaymentsAmount() ?? 0;
    }

    function pinDifferenceAmount() {
      return dailyBalance.value?.getPinDifferenceAmount() ?? 0;
    }

    function totalOfferedAmount() {
      return dailyBalance.value?.getOfferedAmount() ?? 0;
    }

    function totalRepresentationAmount() {
      return dailyBalance.value?.getRepresentationAmount() ?? 0;
    }

    function turnoverAmount() {
      return dailyBalance.value?.getTurnoverAmount() ?? 0;
    }

    function turnoverWithVouchersAmount() {
      return dailyBalance.value?.getTurnoverWithVouchersAmount() ?? 0;
    }

    function vouchersAmount() {
      return dailyBalance.value?.getVoucherAmount() ?? 0;
    }

    function totalInCashRegisterAmount() {
      return dailyBalance.value?.getInCashRegisterAmount() ?? 0;
    }

    function differenceAmount() {
      return dailyBalance.value?.getDifferenceAmount() ?? 0;
    }

    function voidedAmount() {
      return dailyBalance.value?.getVoidAmount() ?? 0;
    }

    async function updateDailyBalance() {
      updating.value = true;
      if (dailyBalanceData.value) {
        dailyBalanceData.value.userData.coins = coinModel.value;
        dailyBalanceData.value.userData.pins = pinModel.value;
        dailyBalanceData.value.userData.payments = paymentsModel.value;
        dailyBalanceData.value.userData.diff = String(differenceAmount());
        dailyBalanceData.value = await dailyBalanceApi.updateDailyBalance(
          props.establishmentId,
          props.dob,
          dailyBalanceData.value,
        );
      }
      updating.value = false;
    }

    function addPin() {
      pinModel.value.push(0);
    }

    function addPayment() {
      paymentsModel.value.push({
        name: '',
        amount: 0,
      });
    }

    return {
      loading,
      updating,
      errorMessage,
      establishment,
      dailyBalanceData,
      dailyBalance,
      tenders,
      coinModel,
      pinModel,
      paymentsModel,
      debtorFrom,
      debtorTo,
      updateDailyBalance,
      addPin,
      addPayment,
      currency,
      totalDebtorAmount,
      totalCoinsAmount,
      coinsDifferenceAmount,
      totalUserPinAmount,
      totalIZettle,
      totalPayments,
      totalSpecialPaymentsAmount,
      pinDifferenceAmount,
      totalOfferedAmount,
      totalRepresentationAmount,
      turnoverAmount,
      turnoverWithVouchersAmount,
      vouchersAmount,
      totalInCashRegisterAmount,
      differenceAmount,
      voidedAmount,
    };
  },
});
