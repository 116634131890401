













































































import { defineComponent, ref } from '@vue/composition-api';
import { useRouter } from '@u3u/vue-hooks';
import { format, parseISO } from 'date-fns';
import ManagerSummary from './ManagerSummary.vue';
import ShiftReviewsSummary from '../shiftReview/ShiftReviewsSummary.vue';
import IncidentReportSummary from '../incidentReports/IncidentReportSummary.vue';
import JournalNotesSummary from '../journalNotes/JournalNotesSummary.vue';

export default defineComponent({
  components: {
    ManagerSummary,
    ShiftReviewsSummary,
    IncidentReportSummary,
    JournalNotesSummary,
  },
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup() {
    const { route } = useRouter();
    const date = ref(route.value.params.date);
    const formatTime = (str: string) => {
      const d = parseISO(str);
      return format(d, 'HH:mm');
    };

    return {
      date,
      formatTime,
    };
  },
});
