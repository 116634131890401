










import { defineComponent, PropType, ref } from '@vue/composition-api';
import ReceiptContactDetailsForm from './ReceiptContactDetailsForm.vue';
import { UpdateReceiptContactDetailsDto, ReceiptDto } from '@/common/types';
import { updateContactDetails } from '@/api/receipt';

export default defineComponent({
  components: {
    ReceiptContactDetailsForm,
  },
  props: {
    receipt: {
      required: true,
      type: Object as PropType<ReceiptDto>,
    },
    establishmentId: {
      required: true,
      type: Number,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const error = ref('');

    const submitForm = async (dto: UpdateReceiptContactDetailsDto) => {
      try {
        loading.value = true;
        await updateContactDetails(props.receipt.id, dto);
        emit('refresh');
      } catch (e) {
        error.value = e;
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      error,
      submitForm,
    };
  },
});
