









































































































































































import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import dailyBalanceApi, { DailyBalanceDto } from '@/api/dailyBalanceApi';
import { findById } from '@/api/establishments';
import { Establishment } from '@/common';

interface CountForm {
  coins: Coin[];
  count: Count[];
}

interface Coin {
  coinName: string;
  roleAmount: number;
  count: number;
}

interface Count {
  name: string;
  number: number | undefined;
  count: number;
}

const CurrencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

export default defineComponent({
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    dob: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const loading = ref(false);
    const updating = ref(false);
    const errorMessage = ref('');
    const dailyBalanceData = ref<DailyBalanceDto | null>(null);
    const establishment = ref<Establishment | null>(null);

    const countForm = reactive<CountForm>({
      coins: [
        {
          coinName: '0.05',
          roleAmount: 2.5,
          count: 0,
        },
        {
          coinName: '0.10',
          roleAmount: 4,
          count: 0,
        },
        {
          coinName: '0.20',
          roleAmount: 8,
          count: 0,
        },
        {
          coinName: '0.50',
          roleAmount: 20,
          count: 0,
        },
        {
          coinName: '1.00',
          roleAmount: 25,
          count: 0,
        },
        {
          coinName: '2.00',
          roleAmount: 50,
          count: 0,
        },
        {
          coinName: '5.00',
          roleAmount: 5,
          count: 0,
        },
        {
          coinName: '10.00',
          roleAmount: 10,
          count: 0,
        },
        {
          coinName: '20.00',
          roleAmount: 20,
          count: 0,
        },
        {
          coinName: '50.00',
          roleAmount: 50,
          count: 0,
        },
      ],
      count: [],
    });

    const loadEstablishment = async () => {
      establishment.value = await findById(props.establishmentId);
    };

    const loadDailyBalance = async () => {
      const dailyBalance = await dailyBalanceApi.search({
        establishmentId: props.establishmentId,
        dob: props.dob,
      });

      if (dailyBalance.length) {
        dailyBalanceData.value = dailyBalance[0];
      }
    };

    const loadData = async () => {
      try {
        loading.value = true;
        await Promise.all([loadDailyBalance(), loadEstablishment()]);
        if (dailyBalanceData.value?.miscData?.count) {
          countForm.count = dailyBalanceData.value.miscData.count as Count[];
        }

        if (dailyBalanceData.value?.miscData?.coins) {
          countForm.coins = dailyBalanceData.value.miscData.coins as Coin[];
        }

        establishment.value?.inventoryItems?.forEach(item => {
          const present = countForm.count.find(x => x.name === item.name);
          if (!present) {
            countForm.count.push({
              name: item.name,
              count: 0,
              number: item.expectedNumber,
            });
          }
        });
      } catch (e) {
        console.log(e);
        if (typeof e === 'string') {
          errorMessage.value = e;
        } else {
          errorMessage.value = 'er is iets misgegaan';
        }
      } finally {
        loading.value = false;
      }
    };

    loadData();

    const changeBin = computed(() => {
      let changeBin = 1000;
      if (establishment.value) {
        changeBin = establishment.value.changeBin ?? 1000;
      }
      return changeBin;
    });

    const updateDailyBalance = async () => {
      if (dailyBalanceData.value) {
        if (dailyBalanceData.value.miscData === null) {
          dailyBalanceData.value.miscData = {};
        }
        dailyBalanceData.value.miscData.count = countForm.count;
        dailyBalanceData.value.miscData.coins = countForm.coins;
        dailyBalanceData.value = await dailyBalanceApi.updateDailyBalance(
          props.establishmentId,
          props.dob,
          dailyBalanceData.value,
        );
      }
    };

    const totalCoins = (coins: Coin[]) => {
      let total = 0;
      coins.forEach(coin => {
        total = total + coin.count * coin.roleAmount;
      });
      return total;
    };

    const count = (amount: number, count: number) => {
      if (count === undefined) {
        return 0;
      } else {
        return amount * count;
      }
    };

    const currency = (num: number) => {
      return CurrencyFormatter.format(num);
    };

    return {
      loading,
      updating,
      errorMessage,
      dailyBalanceData,
      establishment,
      countForm,
      changeBin,
      updateDailyBalance,
      totalCoins,
      count,
      currency,
    };
  },
});
