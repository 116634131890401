











































































































































































































































































































import { defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { UpdateReceiptContactDetailsDto, ReceiptDto, Reservation } from '@/common/types';
import ReservationSelect from '../reservations/ReservationSelect.vue';
import ReceiptInfo from './ReceiptInfo.vue';

const currencyFormatter = Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider,
    ReceiptInfo,
    ReservationSelect,
  },
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    receipt: {
      required: true,
      type: Object as PropType<ReceiptDto>,
    },
  },
  setup(props, { emit }) {
    const amount = ref('');
    const data = reactive<Partial<UpdateReceiptContactDetailsDto>>({
      reference: '',
      contactPerson: '',
      costCenter: '',
      department: '',
      debtorName: '',
      debtorAddress1: '',
      debtorAddress2: '',
      debtorHousenumber: '',
      debtorPostalCode: '',
      debtorCity: '',
      debtorCountry: '',
      debtorPhone: '',
      debtorEmail: '',
    });

    watch(
      () => props.receipt,
      receipt => {
        data.reference = receipt?.contactDetails?.reference;
        data.contactPerson = receipt?.contactDetails?.contactPerson;
        data.costCenter = receipt?.contactDetails?.costCenter;
        data.department = receipt?.contactDetails?.department;
        data.debtorName =
          receipt?.contactDetails?.debtorName ?? receipt.accountIdent ?? receipt.accountNumber;
        data.debtorAddress1 = receipt?.contactDetails?.debtorAddress1 ?? receipt.accountAddress1;
        data.debtorAddress2 = receipt?.contactDetails?.debtorAddress2 ?? receipt.accountAddress2;
        data.debtorHousenumber = receipt?.contactDetails?.debtorHousenumber;
        data.debtorPostalCode = receipt?.contactDetails?.debtorPostalCode ?? receipt.accountZipCode;
        data.debtorCity = receipt?.contactDetails?.debtorCity ?? receipt.accountCity;
        data.debtorCountry = receipt?.contactDetails?.debtorCountry;
        data.debtorPhone = receipt?.contactDetails?.debtorPhone ?? receipt.accountPhone;
        data.debtorEmail = receipt?.contactDetails?.debtorEmail;
        if (receipt) {
          amount.value = currencyFormatter.format(receipt.salesAmount);
        } else {
          amount.value = '';
        }
      },
      { immediate: true },
    );

    const onSubmit = () => {
      emit('submit', data);
    };

    const onReservationSelect = (reservation?: Reservation) => {
      if (reservation) {
        data.debtorName = reservation.companyName ?? undefined;
        data.contactPerson = reservation.firstName + ' ' + reservation.lastName;
        data.debtorPhone = reservation.phoneNumber;
        data.debtorEmail = reservation.email;
        data.costCenter = reservation.costCenter ?? undefined;
        data.department = reservation.department ?? undefined;
      }
    };

    return {
      data,
      onSubmit,
      onReservationSelect,
      amount,
    };
  },
});
