



















































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { addDays, format, subDays } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import journalApi from '@/api/journalApi';
import { Journal } from '@/common/types';
import JournalOverview from './components/JournalOverview.vue';
import DailyBalance from './components/dailyBalance/DailyBalanceView.vue';
import ExtraView from './components/extra/extraView.vue';
import { RawLocation } from 'vue-router';
import { useRouter } from '@u3u/vue-hooks';

const tabs = ['dag', 'avond', 'kassa', 'afsluiten'];

export default defineComponent({
  components: {
    JournalOverview,
    DailyBalance,
    ExtraView,
  },
  setup() {
    const { route, router } = useRouter();

    const dob = ref(format(new Date(), 'yyyy-MM-dd'));
    const establishmentId = ref<number | undefined>();
    const loading = ref(false);
    const errorMessage = ref('');
    const tab = ref(0);
    const selectedTab = ref('');
    const journals = ref<Journal[]>([]);

    const dateDay = computed(() => {
      return format(new Date(dob.value), 'EEEE d MMMM', { locale });
    });

    const dateWeek = computed(() => {
      return format(new Date(dob.value), 'II', { locale });
    });

    const establishment = computed(() => {
      switch (establishmentId.value) {
        case 1:
          return 'Walrus Sneek';
        case 2:
          return 'Walrus Leeuwarden';
        case 3:
          return 'Proeflokaal Sneek';
        case 4:
          return 'Groote Wielen';
        default:
          return '';
      }
    });

    const dayJournal = computed(() => {
      return journals.value.find(j => j.name === 'dag');
    });

    const eveningJournal = computed(() => {
      return journals.value.find(j => j.name === 'avond');
    });

    function getRouteHash() {
      return route.value.hash ? route.value.hash.replace('#', '') : undefined;
    }

    watch(tab, tab => {
      const hash = getRouteHash();
      if (hash !== tabs[tab]) {
        router.push('#' + tabs[tab]);
      }
    });

    watch(
      route,
      async route => {
        dob.value = route.params?.date;
        establishmentId.value = Number(route.params?.establishmentId);
        if (!dob.value || !establishmentId.value) {
          router.push({
            name: 'JournalsView',
            params: { establishmentId: String(establishmentId.value) },
          });
        }

        const hash = getRouteHash();
        if (hash) {
          tab.value = Math.max(tabs.indexOf(hash), 0);
        }

        try {
          loading.value = true;
          journals.value = await journalApi.findAllForEstablishmentByDate(
            establishmentId.value,
            dob.value,
          );
          journals.value.sort((a, b) => a.name.localeCompare(b.name)).reverse();
        } catch (e) {
          if (typeof e === 'string') {
            errorMessage.value = e;
          } else {
            errorMessage.value = 'er is iets misgegaan';
          }
        } finally {
          loading.value = false;
        }
      },
      { immediate: true, deep: true },
    );

    function navigatedToDate(date: string) {
      const hash = getRouteHash();
      const location: RawLocation = {
        name: 'JournalView',
        params: { date },
      };
      if (hash) {
        location.hash = '#' + getRouteHash();
      }
      router.push(location);
    }

    function next() {
      const date = format(addDays(new Date(dob.value), 1), 'yyyy-MM-dd');
      navigatedToDate(date);
    }

    function prev() {
      const date = format(subDays(new Date(dob.value), 1), 'yyyy-MM-dd');
      navigatedToDate(date);
    }

    return {
      dob,
      establishmentId,
      loading,
      errorMessage,
      tab,
      selectedTab,
      journals,
      dateDay,
      dateWeek,
      establishment,
      dayJournal,
      eveningJournal,
      next,
      prev,
    };
  },
});
