






























































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { IncidentReport, Journal, JournalNote, Tag } from '@/common';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';

interface IncidentObject {
  date: Date;
  subject: string;
  tags: Tag[] | undefined;
  incident: IncidentReport;
  name: string;
  establishmentId: number;
  dob: string;
}

interface IconObject {
  icon: string;
  variant: string;
}

export default defineComponent({
  props: {
    journals: {
      required: true,
      type: Array as PropType<Journal[]>,
    },
  },
  setup(props) {
    const showModal = ref(false);
    const selectedNote = ref<JournalNote>({
      id: 0,
      journalId: 0,
      subject: '',
      comment: '',
      rating: 0,
    });

    const incidents = computed(() => {
      const incidents: IncidentObject[] = [];
      props.journals.forEach(journal => {
        if (journal.incidentReports) {
          journal.incidentReports.forEach(report => {
            incidents.push({
              date: new Date(journal.dob),
              subject: report.incident?.name ?? '',
              tags: report.tags,
              incident: report,
              name: journal.name,
              establishmentId: journal.establishmentId,
              dob: format(new Date(journal.dob), 'yyyy-MM-dd'),
            });
          });
        }
      });
      return incidents;
    });

    const getRatingIcon = (rating: number | undefined | null) => {
      const result: IconObject = {
        icon: '',
        variant: '',
      };

      if (rating === undefined || rating === null) {
        result.icon = 'emoji-smile';
        result.variant = 'secondary';
      } else if (rating === 1) {
        result.icon = 'emoji-frown';
        result.variant = 'danger';
      } else if (rating === 5) {
        result.icon = 'emoji-laughing';
        result.variant = 'success';
      }
      return result;
    };

    const formatDate = (date: Date) => {
      return format(date, 'EEEE', { locale });
    };

    const formatDateTime = (date: string) => {
      return format(new Date(date), 'dd-MM-yyyy H:mm:s');
    };

    return {
      showModal,
      selectedNote,
      incidents,
      getRatingIcon,
      formatDate,
      formatDateTime,
      fields: [
        {
          key: 'date',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'subject',
          label: 'Onderwerp',
          sortable: false,
        },
        {
          key: 'tags',
          label: '',
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ],
    };
  },
});
