

































































import { defineComponent, PropType } from '@vue/composition-api';
import { Reservation } from '@/common';

interface ReservationData {
  dinner: number;
  lunch: number;
  drinks: number;
  highTea: number;
  highWineBeer: number;
  other: number;
  reservations: Reservation[];
}

export default defineComponent({
  props: {
    reservations: {
      required: true,
      type: Object as PropType<ReservationData>,
    },
  },
  setup() {
    return {};
  },
});
