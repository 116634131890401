import client from '../client';
import { UpdateReceiptContactDetailsDto, ReceiptDto } from '@/common';
import { Auth } from '@/services/auth';

export async function findById(establishmentId: number, receiptId: number) {
  const result = await client.get<ReceiptDto>(`/receipts/${receiptId}`);
  return result.data;
}

export async function findInDateRange(establishmentId: number, d1: string, d2: string) {
  const params = { d1, d2 };
  const result = await client.get<ReceiptDto[]>(`/establishments/${establishmentId}/receipts`, {
    params,
  });
  return result.data;
}

export async function downloadInvoicePdf(id: number) {
  const token = await Auth.getToken();
  window.open(`${client.defaults.baseURL}/receipts/${id}/invoice?access_token=${token}`, '_blank');
}

export async function updateContactDetails(id: number, data: UpdateReceiptContactDetailsDto) {
  const result = await client.put<ReceiptDto>(`/receipts/${id}/contact-details`, data);
  return result.data;
}
