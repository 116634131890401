









































































































import { defineComponent, ref, watch } from '@vue/composition-api';
import { getReservations } from '@/api/reservations';
import { Reservation } from '@/common/types';
import { addDays, format } from 'date-fns';

export default defineComponent({
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    dob: {
      required: true,
      type: String,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const error = ref('');
    const reservations = ref<Reservation[]>([]);
    const filter = ref<string | null>(null);
    const perPage = ref(10);
    const currentPage = ref(1);

    const selectedReservation = ref<Reservation | null>(null);

    const loadReservations = async () => {
      try {
        loading.value = true;
        const from = format(new Date(props.dob), 'yyyy-MM-dd');
        const to = format(addDays(new Date(props.dob), 1), 'yyyy-MM-dd');
        reservations.value = await getReservations(props.establishmentId, from, to);
      } catch (e) {
        error.value = e;
      } finally {
        loading.value = false;
      }
    };

    const selectReservation = (reservation: Reservation) => {
      emit('selectedReservation', reservation);
    };

    watch(
      () => props.establishmentId,
      () => {
        loadReservations();
      },
      { immediate: true },
    );

    return {
      loading,
      error,
      reservations,
      filter,
      perPage,
      currentPage,
      selectedReservation,
      selectReservation,
      fields: [
        {
          key: 'select',
          sortable: false,
        },
        {
          key: 'reservationType',
          sortable: true,
        },
        {
          key: 'reservationType',
          sortable: true,
        },
        {
          key: 'lastName',
          sortable: true,
        },
        {
          key: 'company',
          sortable: true,
        },
      ],
    };
  },
});
